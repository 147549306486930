import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ContactusService } from '../services/contactus.service';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.css']
})
export class ContactusComponent implements OnInit {
  contactusForm: any;
  show_loader: boolean;
  mob_menu: boolean = false;
  sending = false;
  hide_icon: any = false;
  @ViewChild('myDiv') myDiv: ElementRef<HTMLElement>;
  message: any;
  constructor(public formBuilder: FormBuilder, private router: Router, private conatctus: ContactusService) {
    this.contactusForm = formBuilder.group({
      name: ['', Validators.compose([Validators.required])],
      email: ['', Validators.compose([Validators.required, Validators.pattern('[A-Za-z0-9._%+-]{2,}@[a-zA-Z]{1,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})'), Validators.maxLength(50), Validators.minLength(5)])],
      number: [''],
      //   number: ['', Validators.compose([Validators.required, Validators.pattern('[0-9+-/)//(/]')])],
      Message: [''],

    })
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
    let startDate: any = localStorage.getItem('verfied_time');
    if (startDate) {
      var endDate = new Date();
      var seconds = (endDate.getTime() - startDate) / 1000;
      console.log("ss", seconds);
      if (seconds > 3600) {
        localStorage.removeItem('verfied_time');
        localStorage.removeItem('user_info');
        localStorage.removeItem('user_verfied');
        localStorage.removeItem('cart');
        this.router.navigate(['/home'])
      }
    }
  }
  meanu_toggle() {

    // this.hide_icon=false;
    this.mob_menu = !this.mob_menu;
  }


  ngOnInit() {
  }
  submitcontact(contactusForm) {
    if (this.contactusForm.valid) {
      this.show_loader = true;
      this.sending = true;
      const conact_info = {
        name: contactusForm.value.name,
        email: contactusForm.value.email,
        phone: contactusForm.value.number,
        message: contactusForm.value.Message
      }
      this.conatctus.contact_us(conact_info).subscribe((res: any) => {
        this.show_loader = false;
        this.sending = false;
        if (res.result === "success") {
          // let el: HTMLElement = this.myDiv.nativeElement;
          // el.click();
          this.message = res.msg;
        }

      }, (err) => {
        this.show_loader = false;
        this.sending = false;


      })
    } else {

    }


  }
  omit_special_char(event) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }
}
