import { Component, NgZone, HostListener, ElementRef, ViewChild, OnInit } from '@angular/core';

import { FacebookService, InitParams } from 'ngx-facebook';
import { Router } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  @ViewChild('amnly_navbar') navbar: ElementRef;
  title = 'Warranty';
  cart: any;
  showPreloader = true;
  header_scrolled: boolean = false;
  deviceInfo: any;
  editpage: string;
  //@ViewChild('myDivs') myDivs: ElementRef<HTMLElement>;
  constructor(private _ngZone: NgZone, private router: Router, private facebookService: FacebookService, private elRef: ElementRef) {
    let user_verfied = localStorage.getItem('user_verfied');
    this.editpage = localStorage.getItem('editpage')
    if (user_verfied) {
      console.log("TCL: AppComponent -> constructor -> user_verfied", user_verfied)
      let startDate: any = localStorage.getItem('verfied_time');
      if (startDate) {
        var endDate = new Date();
        var seconds = (endDate.getTime() - startDate) / 1000;
        console.log("ss", seconds);
        if (seconds > 3600) {
          localStorage.removeItem('verfied_time');
          localStorage.removeItem('user_info');
          localStorage.removeItem('user_verfied');
          localStorage.removeItem('cart');
          localStorage.removeItem('editpage');
          this.router.navigate(['/home'])
        }
      }
    } else {
    
      let startDatea: any = localStorage.getItem('order_time');
      var endDate = new Date();
      var seconds = (endDate.getTime() - startDatea) / 1000;
      if (seconds > 3600) {
        localStorage.removeItem('cart');
        localStorage.removeItem('user_info');
        this.router.navigate(['/home'])
      }



    }


  }

  @HostListener('window:scroll', ['$event']) onScrollEvent($event) {
    if (window.pageYOffset > 200) {
      this.header_scrolled = true;
    } else {
      this.header_scrolled = false;
    }
  }
  // ngOnInit() {


  // }
  ngOnInit(): void {
    this.epicFunction();
    this.initFacebookService();
  }
  epicFunction() {
  
  }
  private initFacebookService(): void {
    const initParams: InitParams = { xfbml: true, version: 'v3.2' };
    this.facebookService.init(initParams);
  }
}
