import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.css']
})
export class IntroComponent implements OnInit {
images: any[] = [{src:"assets/slider_images/Camera.svg"},
                 {src:"assets/slider_images/Fridge.svg"},
                 {src:"assets/slider_images/Gaming.svg"},
                 {src:"assets/slider_images/Laptop.svg"},
                 {src:"assets/slider_images/Others.svg"},
                 {src:"assets/slider_images/Smart Phone.svg"},
                 {src:"assets/slider_images/Televesion.svg"},
                 {src:"assets/slider_images/Washing Machine.svg"},
                 {src:"assets/slider_images/Washing Machine.svg"},
]

  constructor() {
 
   }

  ngOnInit() {
    console.log();
    document.getElementsByClassName('video');
  }

}
