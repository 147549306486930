import { Component, OnInit, NgZone, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { EwplansService } from '../services/ewplans.service';
import { FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

declare var Pikaday: any
declare var $: any;
@Component({
  selector: 'app-addmore',
  templateUrl: './addmore.component.html',
  styleUrls: ['./addmore.component.css']
})
export class AddmoreComponent implements OnInit {
  departmentid: '';
  ewPlans: any;
  hideform: boolean;
  ew_select: boolean = false;
  rangeMin: number = 0.00;
  rangeMax: number = 0.00;
  invalidPrice: boolean = false;
  selectedRangeMax: number = 100;
  @ViewChild('slider') slider;
  planLoader: boolean = false;
  noEwPlansFound: boolean = false;
  noAdPlansFound: boolean = false;
  acc_damage: boolean = false;
  searchForWarrantyForm: any;
  device_types: any;
  country: any;
  country_data: any;
  parchase_from: any;
  show_business: boolean = false;
  currency: any;
  adPlans: any;
  group_id: any;
  Emplan: any;
  AllPlans: any;
  Amplan: any;
  per: number;
  orderTotal: any = 0;
  percentagevalue: any = 0;
  selectedEwPlan: any;
  selectedAdhPlan: any;
  eWTotal = 0;
  aDhTotal = 0;
  exitingItems: any = [];
  show_error: boolean;
  myDate: string;
  department: any;
  @ViewChild('selectdp') selectdp: ElementRef;
  device_gropu: any;
  contry_name: any;
  contry: any;
  countryname: any = "Select Country";
  order_result: any;
  country_res: any;
  country_values: any;
  item_group: any = "Item group";
  item_category: any = "item category";
  device_purchased_form: any = "Choose your store";
  invoice_number: any;
  plan_amount: any;
  cat_info: any;
  order_id: any;
  old_per: any = 0;
  mob_menu: boolean = false;
  hide_icon: any = false;
  hide_accedental: boolean = true;
  cart: any;
  AllPlans_value: any;
  month: any;
  day: any;
  year: any;
  current_val: any;
  k: any;
  plans_ad: any;
  purchase_from: any;
  math = Math;
  min_month: string;
  min_day: string;
  min_year: string;
  @ViewChild('datepicker2') datepicker2: ElementRef;
  constructor(private _ewService: EwplansService, private route: ActivatedRoute,public cd: ChangeDetectorRef,
    public formBuilder: FormBuilder, private router: Router, private ngZone: NgZone) {

    let startDate: any = localStorage.getItem('verfied_time');
    if (startDate) {
      var endDate = new Date();
      var seconds = (endDate.getTime() - startDate) / 1000;
      console.log("ss", seconds);
      if (seconds > 3600) {
        localStorage.removeItem('verfied_time');
        localStorage.removeItem('user_info');
        localStorage.removeItem('user_verfied');
        localStorage.removeItem('cart');
        this.router.navigate(['/home'])
      }
    }

    if (localStorage.getItem('cart')) {
      this.exitingItems = JSON.parse(localStorage.getItem('cart'));

    }

    this.searchForWarrantyForm = formBuilder.group({

      Device: ['', Validators.compose([Validators.required])],

      business: [''],

      price: [''],

      Invoice_number: ['', Validators.compose([Validators.required, Validators.maxLength(30), Validators.minLength(2)])],

      Purchase_from: ['', Validators.compose([Validators.required])],
      // group: ['', Validators.compose([Validators.required])],
      purchase_date: ['', Validators.compose([Validators.required])],
    });

    this._ewService.loaddevicetype().subscribe((res: any) => {
      console.log("TCL: AddmoreComponent -> devres", res)


      this.device_types = res;

      const result = this.device_types.filter(types => {
        return types.category_id == this.order_result[0].device_cat_id;

      });

      console.log("TCL: AddmoreComponent -> resul t", result)

      this.cat_info = result[0];
      this.searchForWarrantyForm.controls['Device'].patchValue(JSON.stringify(this.cat_info));
      this.ngZone.run(() => {
        this.item_category = result[0].category_name;
      })
    }, () => {

    }, () => {
      setTimeout(() => {
        $('.selectpicker').selectpicker('refresh');
      }, 150);
    })



    this.route.queryParams.subscribe(params => {

      let order_id = params["planId"];
      if (order_id) {
        this.order_id = order_id;
        this.exitingItems = JSON.parse(localStorage.getItem('cart'));
        this.order_result = this.exitingItems.filter(item => item.planId == order_id);
        console.log("TCL: AddmoreComponent ->  this.order_result ", this.order_result)
        this.purchase_from = this.order_result[0].device_purchased_form;
        console.log("TCL: AddmoreComponent ->  this.purchase_from", this.purchase_from)
        this.device_purchased_form = this.order_result[0].device_purchased_form.pfm_name;

        this.plan_amount = this.order_result[0].plan_amount
        this.current_val = this.plan_amount;
        this.invoice_number = this.order_result[0].invoice_number;


        this.searchForWarrantyForm.controls['Purchase_from'].patchValue(JSON.stringify(this.purchase_from));
        this.searchForWarrantyForm.controls['Invoice_number'].patchValue(this.invoice_number);
        let date = this.order_result[0].purchase_date;
        console.log("TCL: AddmoreComponent -> date", date)
        this.myDate = '2019-12-10';


        this.group_id = this.order_result[0].device_group_id;
        // this.order_result[0].device_cat_id
        let country_data = JSON.parse(localStorage.getItem('country_name'));
        const pakage_info = {
          country_id: country_data.country_id,
          group_id: this.group_id
        }
        this._ewService.loadEWPlans(pakage_info).subscribe((res: any) => {
          console.log("data after on load", res);

          this.planLoader = false;
          if (res.result == "success") {
            this.AllPlans = res.data[0];
            this.ewPlans = this.AllPlans.ED_packages;
            let adhplans=this.AllPlans.ADH_packages
            this.adPlans = true;
            

            
            // this.adPlans = this.AllPlans.ADH_packages;
            this.adPlans = this.AllPlans.ADH_packages.filter(e => e.adh_numberof_years <= this.order_result[0].ewPlan.ew_numberof_years);
            this.noEwPlansFound = false;
            let acc=false;
            adhplans.map((res:any)=>{
              if(res.adh_package_id===this.adPlans[0].adh_package_id){
                     acc=true;
              }
            })
           
            setTimeout(() => {
              this.acc_damage = acc;
              this.hide_accedental = true;
              // this.cd.detach();
            }, 100);
          
          } else {
            this.noEwPlansFound = true;
          }
        
          this.selectedEwPlan = this.order_result[0].ewPlan;
          
          
          if( Object.keys(  this.selectedAdhPlan).length===0){
            this.order_result = this.exitingItems.filter(item => item.planId == order_id);
            this.selectedAdhPlan = this.order_result[0].adhPlan;
          }else{
            this.selectedAdhPlan = this.order_result[0].adhPlan;
          }
          console.log(" this.selectedEwPlan", this.selectedAdhPlan)
        }, (err) => {
          this.planLoader = false;
          this.noEwPlansFound = true;
        })
      }


    });
    this._ewService.loadgroups().subscribe((res: any) => {


      this.device_gropu = res;
      console.log("TCL: AddmoreComponent ->  this.device_gropu", this.device_gropu)


    }, () => {

    }, () => {

      if (this.order_result !== undefined && this.order_result.length > 0) {

        const result = this.device_gropu.filter(group => {
          return group.group_id == this.order_result[0].device_group_id

        });
        console.log("TCL: AddmoreComponent -> result", result)
        this.item_group = result[0].group_name;

        this._ewService.loaddevicetype().subscribe((res: any) => {
          console.log("TCL: AddmoreComponent -> devres", res)




          const result = res.filter(types => {
            return types.category_id == this.order_result[0].device_cat_id;

          });

          this.cat_info = result[0];

          //  this.department = this.cat_info;
          this.ngZone.run(() => {


            this.item_category = result[0].category_name;
          })
          // this.searchForWarrantyForm.controls['Device'].patchValue(  result[0] | json );

        }, () => {

          setTimeout(() => {
            $('.selectpicker').selectpicker('refresh');
          }, 150);
        });
      }

    });
    this._ewService.loadcountries().subscribe((res: any) => {


      this.ngZone.run(() => {

        this.country = res;
        this.country_res = this.country;





      })

    }, error => {

    }, () => {
      setTimeout(() => {
        $('.selectpicker').selectpicker('refresh');
      }, 150);
      if (this.order_result) {
        this.contry = this.country_res.filter(country => country.country_id == this.order_result[0].country_id);





        this.countryname = this.contry[0].country_name;

        this.country_data = this.contry[0];
        this.rangeMin = this.contry[0].min_price;
        this.rangeMax = this.contry[0].max_price;
        this.currency = this.contry[0].currency;
        this.parchase_from = this.contry[0].parchase_form;
        this.selectedRangeMax = this.order_result[0].device_price;
        this.searchForWarrantyForm.controls['price'].patchValue(this.selectedRangeMax);
        // console.log(this.selectedRangeMax);
        // this.item_group = this.order_result[0].device_cat_name;
        // this.countryname =this.country_values.country_name;

        // this.country_data =  this.country_values;
      } else {

        let country_select = JSON.parse(localStorage.getItem('country_name'));

        this.contry = this.country_res.filter(country => country.country_id == country_select.country_id);

        this.countryname = this.contry[0].country_name;
        this.country_data = this.contry[0];
        this.rangeMin = this.contry[0].min_price;
        this.rangeMax = this.contry[0].max_price;
        this.currency = this.contry[0].currency;
        this.parchase_from = this.contry[0].parchase_form;
      }

    })




  }
  

  reset_form() {
    console.log("inside reset");

    this.searchForWarrantyForm.controls['business'].setValue('');
    this.searchForWarrantyForm.controls['price'].setValue('');
    this.searchForWarrantyForm.controls['Invoice_number'].setValue('');
    this.searchForWarrantyForm.controls['Purchase_from'].setValue('');
    this.searchForWarrantyForm.controls['purchase_date'].setValue('');
    //this.AllPlans = null;
    // this.ewPlans = null;
    //this.adPlans = false;
    // this.ewPlans = false;
    this.selectedEwPlan = false;
    this.selectedAdhPlan = false;
    this.aDhTotal = 0;
    this.eWTotal = 0;
    this.acc_damage = false;
    this.plan_amount = 0;
    this.selectedRangeMax = 0;
    if (this.country_data) {
      this.rangeMin = this.country_data.min_price;
      this.rangeMax = this.country_data.max_price;
      this.currency = this.country_data.currency;
    }
  }
  get price() {
    return this.searchForWarrantyForm.controls.price;
  }
  ngAfterViewInit() {
    var picker = new Pikaday({
      field: this.datepicker2.nativeElement,
      format: 'MM/DD/YYYY',
      toString(date, format) {
        // you should do formatting based on the passed format,
        // but we will just return 'D/M/YYYY' for simplicity
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${month}/${day}/${year}`;
      },
      parse(dateString, format) {
        // dateString is the result of `toString` method
        const parts = dateString.split('/');
        const day = parseInt(parts[0], 10);
        const month = parseInt(parts[1], 10) - 1;
        const year = parseInt(parts[2], 10);
        return new Date(year, month, day);
      },
      maxDate: new Date(),
      minDate: this.addMonths(new Date(), -6)
    });
    
  }
  addMonths(date, months) {
    date.setMonth(date.getMonth() + months);
    return date;
  }
  ngOnInit() {
    let dtToday = new Date();
    console.log("TCL: AddmoreComponent -> ngOnInit -> dtToday", dtToday)

    this.month = ("0" + (dtToday.getMonth() + 1)).slice(-2);

    this.day = ("0" + (dtToday.getDate())).slice(-2);
    this.year = dtToday.getFullYear();

    // this.contry = JSON.parse(localStorage.getItem('country_name'));
    dtToday.setMonth(dtToday.getMonth() - 6);
    let datetime = dtToday.toLocaleDateString();
    var datetime_value = datetime.split('/', 3);

    this.min_month = ("0" + (datetime_value[0])).slice(-2);
    this.min_day = ("0" + (datetime_value[1])).slice(-2);
    this.min_year = datetime_value[2];
    this.countryname = this.contry.country_name;

    this.country_data = this.contry;
    this.rangeMin = this.contry.min_price;
    this.rangeMax = this.contry.max_price;
    this.currency = this.contry.currency;
    this.parchase_from = this.contry.parchase_form;






  }


  refresh() {
    this.selectdp.nativeElement.selectpicker('refresh');
  }
  chnageRange(e: any) {
    if (isNaN(e.target.value)) {
      e.target.value = e.target.value.substring(0, e.target.value.length - 1);
      return false;
    }

    // if (!this.group_id) {
    //   return false;
    // }
    // this.selectedRangeMax = e.target.value;
    // this.loadEWPlans();
    this.selectedRangeMax = e.target.value;
    console.log(this.selectedRangeMax);
    if (((parseInt(this.selectedRangeMax.toString())) < (parseInt(this.rangeMin.toString()))) || (parseInt(this.selectedRangeMax.toString()) > parseInt(this.rangeMax.toString())) || !this.selectedRangeMax) {

      this.adPlans = false;
      this.ewPlans = false;
      this.selectedEwPlan = false;
      this.selectedAdhPlan = false;
      this.aDhTotal = 0;
      this.eWTotal = 0;
      this.plan_amount = 0;
      // this.planLoader = true;
      this.acc_damage = false;
      this.planLoader = true;
      this.planLoader = null;
      this.invalidPrice = true;
    } else {

      this.invalidPrice = false;
      this.loadEWPlans();

    }
  }
  loadEWPlans() {
    this.adPlans = false;
    this.ewPlans = false;
    this.selectedEwPlan = false;
    this.selectedAdhPlan = false;
    this.aDhTotal = 0;
    this.eWTotal = 0;
    this.plan_amount = 0;
    // this.planLoader = true;
    this.acc_damage = false;
    this.planLoader = true;
    let country_data = JSON.parse(localStorage.getItem('country_name'));
    const pakage_info = {
      country_id: country_data.country_id,
      group_id: this.group_id
    }
    this._ewService.loadEWPlans(pakage_info).subscribe((res: any) => {

      console.log("TCL: AddmoreComponent -> loadEWPlans ->  this.AllPlans_value", res)
      this.planLoader = false;
      if (res.result == "success") {
        this.AllPlans_value = res.data[0];
        this.valid_form();
        //   this.ewPlans = this.AllPlans.ED_packages;
        //   this.noEwPlansFound = false;
      } else {
        this.noEwPlansFound = true;
      }
    }, (err) => {
      this.planLoader = false;
      this.noEwPlansFound = true;
    })
    //this.selectedRangeMax

  }
  searchForWarranty(searchForm) {

    this.searchForWarrantyForm.value.Device = JSON.stringify(this.cat_info);

    this.searchForWarrantyForm.value.Purchase_from = JSON.stringify(this.purchase_from);
    console.log(this.searchForWarrantyForm);
    if (this.selectedEwPlan) {



      let invoice_number = searchForm.value.Invoice_number;


      let catId = JSON.parse(this.searchForWarrantyForm.value.Device);
      let purchase_from = JSON.parse(this.searchForWarrantyForm.value.Purchase_from);
      let planId = 1;
      if (this.exitingItems.length) {
        planId = this.exitingItems.length + 1;
      }

      let order = {
        "planId": planId,
        "country_id": this.country_data.country_id,
        "country_code": this.country_data.country_code,
        "country_name": this.country_data.country_name,
        "currency": this.country_data.currency,
        "device_cat_id": catId.category_id,
        "device_cat_name": catId.category_name,
        "device_group_id": catId.group_id,
        "device_purchased_form": purchase_from,
        "ewPlan": this.selectedEwPlan,
        "adhPlan": this.selectedAdhPlan,
        "plan_amount": parseFloat((Math.ceil((this.eWTotal + this.aDhTotal) * 10) / 10).toFixed(3)),
        "invoice_number": invoice_number,
        "device_price": searchForm.value.price,
        "purchase_date": searchForm.value.purchase_date
      };
      this.exitingItems.push(order);
      localStorage.setItem('cart', JSON.stringify(this.exitingItems));

      this.router.navigate(['/billing']);
    } else {
      alert('Please select Extended warranty plans')
    }
  }
  PlanName(plan) {
    this.plans_ad = plan;
    console.log("TCL: AddmoreComponent -> PlanName ->  this.plans_ad ", this.Emplan, this.adPlans, this.price.value, this.plans_ad)
    this.plan_amount = '';

    if (this.adPlans && this.adPlans.length > 0) {
      this.percentagevalue = this.plans_ad.ew_package_percentag;
    }
    this.per = ((this.price.value * this.percentagevalue) / 100);
    console.log("TCL: AddmoreComponent -> PlanName ->  this.per", this.per)
    this.aDhTotal = 0;
    this.selectedAdhPlan = {};
    this.acc_damage = false;
    this.Emplan = plan;
    if (this.Emplan) {
      this.selectedEwPlan = plan;


      if (this.Emplan.ew_package_percentag) {
        this.percentagevalue = this.Emplan.ew_package_percentag;
      }
      this.per = ((this.price.value * this.percentagevalue) / 100);
      console.log("TCL: AddmoreComponent -> PlanName -> this.per", this.per)
      this.eWTotal = this.per;
    }


  }
  AccidentalPlan(plan) {

    this.Amplan = plan;
    console.log("TCL: AddmoreComponent -> AccidentalPlan ->  this.Amplan", this.price.value, this.Amplan)
    if (this.Amplan) {
      this.selectedAdhPlan = plan;
      if (this.Amplan.adh_package_percentag) {
        this.percentagevalue = this.Amplan.adh_package_percentag;
      }
      this.per = ((this.price.value * this.percentagevalue) / 100);
      console.log("TCL: AddmoreComponent -> AccidentalPlan -> this.per", this.per)


      this.aDhTotal = this.per;
    }

    if (this.plan_amount) {
      console.log("TCL: AddmoreComponent -> AccidentalPlan -> this.order_result[0].adhPlan", this.order_result[0].adhPlan)

      if (this.order_result[0].adhPlan) {

        console.log(this.order_result[0].adhPlan.adh_package_id, plan.adh_package_id);


        // if (this.order_result[0].adhPlan.adh_package_id === plan.adh_package_id) {

        //   // this.per = ((this.searchForWarrantyForm.value.price * this.percentagevalue) / 100);
        //   this.plan_amount = this.current_val;
        // } else {
        console.log("TCL: AddmoreComponent -> AccidentalPlan -> this.old_per", this.old_per)
        if (this.old_per > 0) {
          // this.current_val = this.plan_amount - this.old_per;
          let old_per = ((this.searchForWarrantyForm.value.price * this.old_per) / 100);
          this.old_per = old_per;
        } else {
          let old_per = ((this.searchForWarrantyForm.value.price * this.order_result[0].adhPlan.adh_package_percentag) / 100);
          let per_v = ((this.searchForWarrantyForm.value.price * plan.adh_package_percentag) / 100);
          this.old_per = per_v;
          this.current_val = this.plan_amount - old_per;
        }






        this.per = ((this.searchForWarrantyForm.value.price * this.percentagevalue) / 100);


        this.plan_amount = this.current_val + this.per;
        // }
      } else {

      }


    }

  }
  customCheck(acc_damage) {
    console.log("plansd",this.plans_ad);


    if (acc_damage) {


      this.aDhTotal = 0;
      this.selectedAdhPlan = {};
      // let old_per = ((this.searchForWarrantyForm.value.price * this.order_result[0].ewPlan.ew_package_percentag) / 100);

      // console.log( this.plans_ad.ew_package_percentag);
      //       if( this.plans_ad){
      // console.log("hii");

      //       }else{
      //         console.log("hiisgs");
      //       }
      if (this.plans_ad) {
        console.log("hiiii");
        let per_v = ((this.searchForWarrantyForm.value.price * this.plans_ad.ew_package_percentag) / 100);
        this.plan_amount = per_v;
      } else {
        console.log("hiiii");
        let old_per = ((this.searchForWarrantyForm.value.price * this.order_result[0].ewPlan.ew_package_percentag) / 100);
        console.log("TCL: AddmoreComponent -> customCheck -> old_per", old_per)
        this.plan_amount = old_per;
      }




    } else {
      if (this.AllPlans && this.AllPlans.ADH_packages) {
        this.adPlans = this.AllPlans.ADH_packages.filter(e => e.adh_numberof_years <= this.selectedEwPlan.ew_numberof_years);
        this.noAdPlansFound = false;
      } else {
        this.noAdPlansFound = true;
      }

    }


  }
  _country(event: any) {
    console.log("TCL: AddmoreComponent -> _country -> event", event)

    let value = JSON.parse(event.target.value)
    console.log("TCL: AddmoreComponent -> _country -> event", event)


    this.country_data = value;

    this.parchase_from = this.country_data.parchase_form;


    localStorage.setItem('country_name', JSON.stringify(this.country_data));
    this.rangeMin = this.country_data.min_price;
    this.rangeMax = this.country_data.max_price;
    this.currency = this.country_data.currency;
  }
  _parchase(event: any) {

    let result = this.parchase_from.filter((res) => {
      return res.pfm_name.trim() == event.target.value.trim()
    })
    this.purchase_from = result[0];
    let value = result[0];


    if (value.pfm_name === "Other") {
      this.show_business = true;
    } else {
      this.show_business = false;
    }

  }
  _device(event: any) {
    console.log("TCL: AddmoreComponent -> _device -> event", event)
    console.log("TCL: AddmoreComponent -> _device -> event", event.target.value)
    console.log("TCL: AddmoreComponent -> _device -> event", event)
    console.log("TCL: AddmoreComponent -> _device -> event", event)

    // this.adPlans = false;

    let result = this.device_types.filter((res: any) => {
      return res.category_name.trim() == event.target.value

    })
    this.cat_info = result[0];


    this.group_id = result[0].group_id;

    this.reset_form();

  }
  isSelectedEw(plan) {


    // this.selectedAdhPlan={};

    if (this.selectedEwPlan) {
      this.ew_select = true;
      // if (this.selectedEwPlan.ew_package_id === plan.ew_package_id) {
      //   plan.selected = true;
      // } else {
      //   plan.selected = false;
      // }


      return this.selectedEwPlan.ew_package_id === plan.ew_package_id;
    }
  }
  isSelectedAdh(plan) {
   //this.selectedAdhPlan.adh_package_id not getting
   console.log(this.selectedAdhPlan.adh_package_id)
      return this.selectedAdhPlan.adh_package_id === plan.adh_package_id;
    
    

   
  }
  _group(event: any) {
    console.log("TCL: AddmoreComponent -> _group -> event", event)

    let value = JSON.parse(event.target.value);
    console.log("TCL: AddmoreComponent -> _group -> event", event)

    //   const result = this.device_types .filter((word )=>{
    //  return word.group_id==value.group_id;

    //   });
    this.planLoader = true;
    // this._ewService.loaddevicetype(value.group_id).subscribe((res: any) => {
    //   this.planLoader = false;

    //   this.device_types = res;

    // });


  }
  editWarranty(searchForm) {
  

    let invoice_number = searchForm.value.Invoice_number;
    console.log("adsdfgfs",invoice_number);
    this.searchForWarrantyForm.value.Device = JSON.stringify(this.cat_info);
    this.searchForWarrantyForm.value.Purchase_from = JSON.stringify(this.purchase_from);
    console.log("TCL: AddmoreComponent -> editWarranty -> invoice_number", this.searchForWarrantyForm)
    let catId;
    if (searchForm.value.Device) {
      console.log("TCL: AddmoreComponent -> editWarranty -> catId", this.searchForWarrantyForm.value.Device)
      catId = JSON.parse(this.searchForWarrantyForm.value.Device);

    } else {
      catId = this.cat_info;
    }
    let purchase_from;
    if (searchForm.value.Purchase_from) {
      purchase_from = JSON.parse(this.searchForWarrantyForm.value.Purchase_from);
    } else {
      purchase_from = this.order_result[0].device_purchased_form;
    }

    console.log("TCL: AddmoreComponent -> editWarranty -> purchase_from", this.eWTotal + this.aDhTotal, this.plan_amount)
    if (this.eWTotal + this.aDhTotal == 0) {
      this.plan_amount;
    } else {
      this.plan_amount = this.eWTotal + this.aDhTotal;
    }

    let order = {
      "planId": parseInt(this.order_id),
      "country_id": this.country_data.country_id,
      "country_code": this.country_data.country_code,
      "country_name": this.country_data.country_name,
      "currency": this.country_data.currency,
      "device_cat_id": catId.category_id,
      "device_cat_name": catId.category_name,
      "device_group_id": catId.group_id,
      "device_purchased_form": purchase_from,
      "ewPlan": this.selectedEwPlan,
      "adhPlan": this.selectedAdhPlan,
      "plan_amount": this.plan_amount,
      "invoice_number": invoice_number,
      "device_price": searchForm.value.price,
      "purchase_date": searchForm.value.purchase_date

    };
    

    let nwarray: any = this.exitingItems.map((item: any) => {
console.log(item.planId, this.order_id);

      if (item.planId == this.order_id) {
        item = order;
      }
      return item;
    })
    console.log("TCL: AddmoreComponent -> editWarranty -> order", nwarray)
    localStorage.setItem('cart', JSON.stringify(nwarray));
    // this.router.navigate(['/editsummery']);
    this.router.navigate(["/auth/editsummary"]);
  }
  meanu_toggle() {

    // this.hide_icon=false;
    this.mob_menu = !this.mob_menu;
  }
  check_date(ev) {
    this.searchForWarrantyForm.controls['purchase_date'].setValue(ev.target.value);
    let today: any = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();

    today = mm + '/' + dd + '/' + yyyy;
    const current_date: any = new Date(today);

    const date2: any = new Date(this.searchForWarrantyForm.value.purchase_date);
    const diffTime = Math.abs(date2 - current_date);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    console.log("TCL: HomesearchComponent -> PlanName -> diffDays", diffDays)
    if (diffDays > 30) {


      console.log("TCL: AddmoreComponent -> PlanName -> this.acc_damage", this.adPlans)
      if (this.adPlans) {
        this.percentagevalue = this.adPlans[0].adh_package_percentag;
      }
      this.per = ((this.price.value * this.percentagevalue) / 100);
      console.log("TCL: AddmoreComponent -> check_date ->  this.per", this.plan_amount)
      this.aDhTotal = 0;
      this.selectedAdhPlan = {};
      this.plan_amount = this.plan_amount - this.per;
      this.hide_accedental = false;

    } else {
      this.hide_accedental = true;
    }


  }
  valid_form() {

    console.log("TCL: AddmoreComponent -> valid_form -> this.AllPlans_value", this.AllPlans_value)
    if (this.order_result) {
      console.log(this.searchForWarrantyForm.value.price.length);

      if (this.searchForWarrantyForm.value.price.length > 0) {

        this.AllPlans = this.AllPlans_value;
        this.ewPlans = this.AllPlans.ED_packages;
        this.noEwPlansFound = false;

      } else {
        this.AllPlans = null;
        this.ewPlans = null;
      }

    } else {
      if ((this.searchForWarrantyForm.value.Device.length > 0) && (this.searchForWarrantyForm.value.price.length > 0)
        && (this.searchForWarrantyForm.value.Invoice_number.length > 0) && (this.searchForWarrantyForm.value.Purchase_from.length > 0)
        && (this.searchForWarrantyForm.value.purchase_date.length > 0)) {
        this.AllPlans = this.AllPlans_value;


        this.ewPlans = this.AllPlans.ED_packages;
        this.noEwPlansFound = false;
      } else {
        this.AllPlans = null;
        this.ewPlans = null;
      }
    }
  }

}