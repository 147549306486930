import { Component, OnInit, NgZone, ViewChild, ElementRef } from '@angular/core';
import { EwplansService } from '../services/ewplans.service';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { PassDataService } from '../pass-data.service';

declare var $: any;
declare var jQuery: any;
declare var Pikaday:any
@Component({
  selector: 'app-homesearch',
  templateUrl: './homesearch.component.html',
  styleUrls: ['./homesearch.component.css'],
})
export class HomesearchComponent implements OnInit {


  @ViewChild('datepicker')
  datepicker: ElementRef;
  ewPlans: any;
  hideform: boolean;
  ew_select: boolean = false;
  rangeMin: number = 0.00;
  rangeMax: number = 0.00;
  selectedRangeMax: number;
  planLoader: boolean = false;
  noEwPlansFound: boolean = false;
  noAdPlansFound: boolean = false;
  acc_damage: boolean = false;
  searchForWarrantyForm: any;
  device_types: any;
  country: any;
  country_data: any;
  parchase_from: any;
  show_business: boolean = false;
  currency: any;
  adPlans: any;
  group_id: any;
  Emplan: any;
  AllPlans: any;
  Amplan: any;
  per: number;
  orderTotal: any = 0;
  percentagevalue: any = 0;
  selectedEwPlan: any;
  selectedAdhPlan: any;
  eWTotal = 0;
  aDhTotal = 0;
  exitingItems: any = [];
  show_error: boolean;
  hide_items: any = ' ';
  dtToday = new Date();
  apiError = false;
  invalidPrice: boolean = false;
  math = Math;
  @ViewChild('myDiv') myDiv: ElementRef<HTMLElement>;
  @ViewChild('datepicker1') datepicker1: ElementRef;

  sliderItems = [
    [
      { item: 'Digital Cameras', img: 'Camera.svg' },
      { item: 'Refrigerators & Freezers', img: 'Fridge.svg' },
      { item: 'Game Consoles', img: 'Gaming.svg' },
      { item: 'Laptops & Notebooks', img: 'Laptop.svg' }],
    [
      { item: 'Tablets', img: 'Tablet.svg' },
      { item: 'Mobile Phones', img: 'SmartPhone.svg' },
      { item: 'Televisions', img: 'Televesion.svg' },
      { item: 'Washing Machines', img: 'WashingMachine.svg' }
    ],
    [
      { item: 'Others', img: 'Others.svg' },
      { item: 'Digital Cameras', img: 'Camera.svg' },
      { item: 'Refrigerators & Freezers', img: 'Fridge.svg' },
      { item: 'Game Consoles', img: 'Gaming.svg' }
    ]
  ];
  sliderItems1 = [

    { item: 'Digital Cameras', img: 'Camera.svg' },
    { item: 'Refrigerators & Freezers', img: 'Fridge.svg' },
    { item: 'Game Consoles', img: 'Gaming.svg' },
    { item: 'Laptops & Notebooks', img: 'Laptop.svg' },

    { item: 'Tablets', img: 'Tablet.svg' },
    { item: 'Mobile Phones', img: 'SmartPhone.svg' },
    { item: 'Televisions', img: 'Televesion.svg' },
    { item: 'Washing Machines', img: 'WashingMachine.svg' }
    ,

    { item: 'Others', img: 'Others.svg' },
    { item: 'Digital Cameras', img: 'Camera.svg' },
    { item: 'Refrigerators & Freezers', img: 'Fridge.svg' },
    { item: 'Game Consoles', img: 'Gaming.svg' }

  ];

  @ViewChild('selectdp') selectdp: ElementRef;


  device_gropu: any;
  itemLoader: boolean;
  date: any;
  hide_accedental: boolean = true;
  category_id: boolean;
  hide_button: boolean;
  AllPlans_value: any;
  hide_plus: boolean;
  month: string;
  day: string;
  year: number;
  devices: string;
  min_month: string;
  min_day: string;
  min_year: string;
  user_verified: string;
  cart_country: any;
  editpage: string;
  message: string;
  constructor(private _ewService: EwplansService,
    private data: PassDataService,
    public formBuilder: FormBuilder, private router: Router, private ngZone: NgZone) {


    if (localStorage.getItem('cart')) {
      this.exitingItems = JSON.parse(localStorage.getItem('cart'));
    }
    this.user_verified = localStorage.getItem('user_verfied');
    this.editpage = localStorage.getItem('editpage')
    this.cart_country = JSON.parse(localStorage.getItem('country_name'));


    this.searchForWarrantyForm = formBuilder.group({
      // tslint:disable-next-line: max-line-length
      Device: ['', Validators.compose([Validators.required])],
      // tslint:disable-next-line: max-line-length
      Country: ['', Validators.compose([Validators.required])],
      // tslint:disable-next-line: max-line-length
      business: [''],
      // tslint:disable-next-line: max-line-length
      price: [''],
      // tslint:disable-next-line: max-line-length
      Invoice_number: ['', Validators.compose([Validators.required, Validators.maxLength(30), Validators.minLength(2)])],
      // tslint:disable-next-line: max-line-length
      Purchase_from: ['', Validators.compose([Validators.required])],
      // group: ['', Validators.compose([Validators.required])],
      purchase_date: ['', Validators.compose([Validators.required])],
    });


    this.loadRequiredData();
    // $(function () {
    //   $("#datepicker").datepicker();
    // });





  }
  loadRequiredData() {
    this.apiError = false;
    this._ewService.loadcountries().subscribe((res: any) => {

      this.apiError = false;
      this.ngZone.run(() => {
        this.country = res;


      })

    }, error => {
      this.apiError = true;
    }, () => {
      setTimeout(() => {
        $('.selectpicker').selectpicker('refresh');
      }, 10);
    })
    this._ewService.loadgroups().subscribe((res: any) => {
      this.apiError = false;

      this.device_gropu = res;

    }, error => {
      this.apiError = true;
    });



    this.itemLoader = true;
    this._ewService.loaddevicetype().subscribe((res: any) => {
      this.itemLoader = false;
      this.hide_items = null;

      this.device_types = res;



    }, () => {

    }, () => {
      setTimeout(() => {
        $('.selectpicker').selectpicker('refresh');
      }, 10);
    });

  }
  get price() {
    return this.searchForWarrantyForm.controls.price;
  }


  ngAfterViewInit(){
    var picker = new Pikaday({ field:this.datepicker1.nativeElement,
      format: 'MM/DD/YYYY',
      toString(date, format) {
            // you should do formatting based on the passed format,
            // but we will just return 'D/M/YYYY' for simplicity
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();
            return `${month}/${day}/${year}`;
        },
        parse(dateString, format) {
            // dateString is the result of `toString` method
            const parts = dateString.split('/');
            const day = parseInt(parts[0], 10);
            const month = parseInt(parts[1], 10) - 1;
            const year = parseInt(parts[2], 10);
            return new Date(year, month, day);
        },
      maxDate:new Date(),
      minDate:this.addMonths(new Date(), -6)
    });
  }

  ngOnInit() {
    this.data.currentMessage.subscribe(message => this.message = message)
    let dtToday = new Date();
    console.log("TCL: AddmoreComponent -> ngOnInit -> dtToday", dtToday)
    console.log("TCL: AddmoreComponent -> ngOnInit -> dtToday", dtToday.toLocaleDateString())

    this.month = ("0" + (dtToday.getMonth() + 1)).slice(-2);

    this.day = ("0" + (dtToday.getDate())).slice(-2);
    this.year = dtToday.getFullYear();

    let device_types = this.device_types;
    let __this = this;


    dtToday.setMonth(dtToday.getMonth() - 6);
    let datetime = dtToday.toLocaleDateString();
    var datetime_value = datetime.split('/', 3);

    this.min_month = ("0" + (datetime_value[0])).slice(-2);
    this.min_day = ("0" + (datetime_value[1])).slice(-2);
    this.min_year = datetime_value[2];
    
    
  }



  addMonths(date, months) {
    date.setMonth(date.getMonth() + months);
    return date;
  }


  // refresh() {
  //   this.selectdp.nativeElement.selectpicker('refresh');
  // }
  chnageRange(e: any) {
    if (isNaN(e.target.value)) {
      e.target.value = e.target.value.substring(0, e.target.value.length - 1);
      return false;
    }

    // if (!this.category_id) {
    //   return false;
    // }
    //  debugger;
    this.selectedRangeMax = e.target.value;



    if (((parseInt(this.selectedRangeMax.toString())) < (parseInt(this.rangeMin.toString()))) || (parseInt(this.selectedRangeMax.toString()) > parseInt(this.rangeMax.toString())) || !this.selectedRangeMax) {
      this.ewPlans = '';
      this.planLoader = true;
      this.invalidPrice = true;
    } else {
      this.invalidPrice = false;
      this.planLoader = true;
      this.loadEWPlans();
    }

  }
  loadEWPlans() {
    this.adPlans = false;
    this.ewPlans = false;
    this.selectedEwPlan = false;
    this.selectedAdhPlan = false;
    this.aDhTotal = 0;
    this.eWTotal = 0;
    // this.planLoader = true;
    this.acc_damage = false;
    let country_data = JSON.parse(localStorage.getItem('country_name'));
    const pakage_info = {
      country_id: country_data.country_id,
      group_id: this.group_id
    }

    this._ewService.loadEWPlans(pakage_info).subscribe((res: any) => {
      this.planLoader = false;

      // this.planLoader = false;
      if (res.result == "success") {
        this.AllPlans_value = res.data[0];
        console.log("TCL: HomesearchComponent -> loadEWPlans ->  this.AllPlans_value", this.AllPlans_value)
        this.valid_form();
        // this.ewPlans = this.AllPlans.ED_packages;
        // this.noEwPlansFound = false;
      } else {
        this.noEwPlansFound = true;
      }
    }, (err) => {
      this.planLoader = false;
      this.noEwPlansFound = true;
    })
    //this.selectedRangeMax

  }
  searchForWarranty(searchForm) {
    console.log("TCL: HomesearchComponent -> searchForWarranty -> searchForm", searchForm)

    if (this.selectedEwPlan) {



      let invoice_number = searchForm.value.Invoice_number;


      let catId = JSON.parse(this.searchForWarrantyForm.controls.Device.value);
      let purchase_from = JSON.parse(this.searchForWarrantyForm.controls.Purchase_from.value);
      let planId = 1;
      if (this.exitingItems.length) {
        planId = this.exitingItems.length + 1;
      }

      let order = {
        "planId": planId,
        "country_id": this.country_data.country_id,
        "country_code": this.country_data.country_code,
        "country_name": this.country_data.country_name,
        "currency": this.country_data.currency,
        "device_cat_id": catId.category_id,
        "device_cat_name": catId.category_name,
        "device_group_id": catId.group_id,
        "device_purchased_form": purchase_from,
        "ewPlan": this.selectedEwPlan,
        "adhPlan": this.selectedAdhPlan,
        "plan_amount": parseFloat((Math.ceil((this.eWTotal + this.aDhTotal) * 10) / 10).toFixed(3)),
        "invoice_number": invoice_number,
        "device_price": searchForm.value.price,
        "purchase_date": searchForm.value.purchase_date

      };
      this.exitingItems.push(order);
      localStorage.setItem('cart', JSON.stringify(this.exitingItems));
      var startDate: any = new Date();
      localStorage.setItem('order_time', startDate.getTime());
      this.router.navigate(['/billing']);
    } else {

    }
  }
  PlanName(plan) {
    console.log("TCL: HomesearchComponent -> PlanName -> plan", this.AllPlans_value.ADH_packages)
    let today: any = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();

    today = mm + '/' + dd + '/' + yyyy;
    const current_date: any = new Date(today);

    const date2: any = new Date(this.searchForWarrantyForm.value.purchase_date);
    const diffTime = Math.abs(date2 - current_date);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    console.log("TCL: HomesearchComponent -> PlanName -> diffDays", diffDays)
    if (diffDays > 30) {


      this.hide_accedental = false;
      this.aDhTotal = 0;
      this.selectedAdhPlan = {};
      this.hide_plus = false;
    } else {
      if (this.AllPlans_value.ADH_packages.length > 0) {
        this.hide_accedental = true;
      } else {
        this.hide_accedental = false;
      }

    }

    this.hide_plus = false;
    // this.per = ((this.price.value * this.percentagevalue) / 100);
    // console.log("TCL: AddmoreComponent -> check_date ->  this.per", this.plan_amount)
    this.aDhTotal = 0;
    this.selectedAdhPlan = {};
    this.acc_damage = false;
    this.Emplan = plan;
    if (this.Emplan) {
      this.selectedEwPlan = plan;
      if (this.Emplan.ew_package_percentag) {
        this.percentagevalue = this.Emplan.ew_package_percentag;
      }
      this.per = ((this.price.value * this.percentagevalue) / 100);
      console.log("TCL: HomesearchComponent -> PlanName -> this.per", this.per)

      this.eWTotal = this.per;
    }


  }
  AccidentalPlan(plan) {
    console.log("TCL: HomesearchComponent -> AccidentalPlan -> plan", plan)

    this.Amplan = plan;
    if (this.Amplan) {
      this.selectedAdhPlan = plan;
      if (this.Amplan.adh_package_percentag) {
        this.percentagevalue = this.Amplan.adh_package_percentag;
      }
      this.per = ((this.price.value * this.percentagevalue) / 100);
      console.log("TCL: HomesearchComponent -> AccidentalPlan -> this.per", this.per)

      this.aDhTotal = this.per;
    }

  }
  customCheck(acc_damage) {
    console.log("TCL: HomesearchComponent -> customCheck -> acc_damage", acc_damage)
    this.hide_plus = false;
    if (acc_damage) {
      // let per = ((this.price.value * this.percentagevalue) / 100);
      // console.log(this.per);

      this.aDhTotal = 0
      this.selectedAdhPlan = {};
      this.hide_plus = false;
      // let v='none';
      // this.isSelectedAdh(v);
      // if (this.Amplan) {
      //   this.selectedAdhPlan = this.Amplan;
      //   if (this.Amplan.adh_package_percentag) {
      //     this.percentagevalue = this.Amplan.adh_package_percentag;
      //   }
      //   this.per = ((this.price.value * this.percentagevalue) / 100);

      //   this.aDhTotal = this.per;
      // }
    } else {
      this.hide_plus = false;
      if (this.AllPlans && this.AllPlans.ADH_packages) {
        this.adPlans = this.AllPlans.ADH_packages.filter(e => e.adh_numberof_years <= this.selectedEwPlan.ew_numberof_years);
        this.noAdPlansFound = false;
        this.hide_plus = true;
      } else {
        this.noAdPlansFound = true;
      }
    }



  }
  _country(event: any) {
    
    this.reset_form();
    this.adPlans = false;
    this.ewPlans = false;
    this.selectedEwPlan = false;
    this.selectedAdhPlan = false;
    this.aDhTotal = 0;
    this.eWTotal = 0;
    this.acc_damage = false;

    let co = JSON.parse(event.target.value)
    if (this.editpage && this.cart_country) {
      if (this.cart_country.country_name === co.country_name) {

        let value = JSON.parse(event.target.value)

        this.country_data = value;

        this.parchase_from = this.country_data.parchase_form;


        localStorage.setItem('country_name', JSON.stringify(this.country_data));
        this.rangeMin = this.country_data.min_price;
        this.rangeMax = this.country_data.max_price;
        this.currency = this.country_data.currency;

      } else {
        let el: HTMLElement = this.myDiv.nativeElement;
        el.click();
      }
    } else {
      let value = JSON.parse(event.target.value)

      this.country_data = value;

      this.parchase_from = this.country_data.parchase_form;


      localStorage.setItem('country_name', JSON.stringify(this.country_data));
      this.rangeMin = this.country_data.min_price;
      this.rangeMax = this.country_data.max_price;
      this.currency = this.country_data.currency;
    }


  }
  _parchase(event: any) {


    let value = JSON.parse(event.target.value);

    if (value.pfm_name === "Other") {
      this.show_business = true;
    } else {
      this.show_business = false;
    }

  }
  _device(event: any) {
    console.log("TCL: HomesearchComponent -> _device -> event", event.target)

    this.reset_form();
    this.valid_form();


    this.category_id = event.target.value.category_id;
    this.adPlans = false;
    this.ewPlans = false;
    this.selectedEwPlan = false;
    this.selectedAdhPlan = false;
    this.aDhTotal = 0;
    this.eWTotal = 0;
    this.acc_damage = false;
    let value = JSON.parse(event.target.value);
    this.group_id = value.group_id;

    this.selectedRangeMax = 0;
    if (this.country_data) {
      this.rangeMin = this.country_data.min_price;
      this.rangeMax = this.country_data.max_price;
      this.currency = this.country_data.currency;
    }

  }
  isSelectedEw(plan) {


    if (this.selectedEwPlan) {

      this.ew_select = true;
      return this.selectedEwPlan.ew_package_id === plan.ew_package_id;
    }
  }
  isSelectedAdh(plan) {


    return this.selectedAdhPlan.adh_package_id === plan.adh_package_id;
  }
  _group(event: any) {





    // let value = JSON.parse(event.target.value);
    //   const result = this.device_types .filter((word )=>{
    //  return word.group_id==value.group_id;

    //   });
    // this.itemLoader = true;
    // this._ewService.loaddevicetype(value.group_id).subscribe((res: any) => {
    //   this.itemLoader = false;
    //   this.hide_items = null;

    //   this.device_types = res;


    // });

  }
  purchase_date(ev: any) {
    console.log("TCL: HomesearchComponent -> purchase_date -> ev", ev)
    this.searchForWarrantyForm.controls['purchase_date'].setValue(ev.target.value);
    // Find any date inputs and override their functionality


    // let k = (<HTMLInputElement>document.getElementById('datepicker'));
    // k.max = new Date().toISOString().split("T")[0];
    let today: any = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();

    today = mm + '/' + dd + '/' + yyyy;
    const current_date: any = new Date(today);

    const date2: any = new Date(ev.target.value);
    const diffTime = Math.abs(date2 - current_date);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));




    if (diffDays > 30) {


      this.hide_accedental = false;
      this.acc_damage = false;
      this.hide_plus = false;
      this.aDhTotal = 0
      this.selectedAdhPlan = {};

    } else {
      this.hide_accedental = true;
    }
  }
  reset_form() {
    this.searchForWarrantyForm.controls['business'].setValue('');
    this.searchForWarrantyForm.controls['price'].setValue('');
    this.searchForWarrantyForm.controls['Invoice_number'].setValue('');
    this.searchForWarrantyForm.controls['Purchase_from'].setValue('');
    this.searchForWarrantyForm.controls['purchase_date'].setValue('');
    // this.AllPlans = null;
    //this.ewPlans = null;
    // this.adPlans = false;
    // this.ewPlans = false;
    this.selectedEwPlan = false;
    this.selectedAdhPlan = false;
    this.aDhTotal = 0;
    this.eWTotal = 0;
    this.acc_damage = false;
    this.selectedRangeMax = 0;
    if (this.country_data) {
      this.rangeMin = this.country_data.min_price;
      this.rangeMax = this.country_data.max_price;
      this.currency = this.country_data.currency;
    }
  }
  valid_form() {



    if (
      this.searchForWarrantyForm.value.Country.length &&
      this.searchForWarrantyForm.value.price.length &&
      this.searchForWarrantyForm.value.Invoice_number.length &&
      this.searchForWarrantyForm.value.Purchase_from.length &&
      this.searchForWarrantyForm.value.purchase_date.length) {
      console.log("inside");

      this.AllPlans = this.AllPlans_value;


      this.ewPlans = this.AllPlans.ED_packages;
      this.noEwPlansFound = false;

    } else {
      this.AllPlans = null;
      this.ewPlans = null;
    }
  }
  isSelected(name) {

    if (this.devices) {
      return name.trim() === this.devices.trim();
    } else {
      return false;
    }

  }
  scrollToSearch() {

    const x = document.querySelector('#search');
    console.log("TCL: HomesearchComponent -> scrollToSearch -> x", x)
    if (x) {
      x.scrollIntoView();
    }
  }
  device(type: any) {
    this.devices = type;
    const result = this.device_types.filter((res: any) => {
      return res.category_name.trim() === this.devices.trim();
    })
    console.log(result[0]);
    if (result && result.length > 0) {

      $('select[name=categories]').val(result[0]);
      $('.bootstrap-select.categories .filter-option .filter-option-inner .filter-option-inner-inner').text(this.devices);
      this.searchForWarrantyForm.controls.Device.setValue(JSON.stringify(result[0]));


      this.category_id = result[0].category_id;
      const value = result[0];
      this.group_id = value.group_id;
      this.reset_form();
      this.valid_form();
    } else {
      this.searchForWarrantyForm.controls.Device.setValue('');
      $('.selectpicker').selectpicker('refresh');
      this.reset_form();
      this.valid_form();
    }
    // setTimeout(() => {
    //   $('.selectpicker').selectpicker('refresh');
    // }, 10);
  }
  Hidenext() {
    var $item = $('#carousel-example');
    var currentItem = $("#carousel-example .item.active");
    console.log("TCL: HomesearchComponent -> Hidenext -> $item", currentItem, $item)
    $('#carousel-slide').on('slid.bs.carousel', function () {
      // var carouselData = $(this).data('bs.carousel');
      // var currentIndex = carouselData.getActiveIndex();
      // var total = carouselData.$items.length;

      // var text = (currentIndex + 1) + " of " + total;
      // $('#carousel-index').text(text);
    });



  }
  clear_cart() {
    localStorage.removeItem('cart');
    this.exitingItems = [];
    this.editpage = '';
    this.cart_country = '';
    localStorage.removeItem('country_name');
    localStorage.removeItem('editpage');
    this.searchForWarrantyForm.controls['Country'].setValue('');
    setTimeout(() => {
      $('.selectpicker').selectpicker('refresh');
    }, 10);
    this.data.changeMessage("Hello from Sibling")
    // this.reset_form();
  }
  cancel() {
    this.searchForWarrantyForm.controls['Country'].setValue('');
    setTimeout(() => {
      $('.selectpicker').selectpicker('refresh');
    }, 10);
  }
}
