import { Component, OnInit } from '@angular/core';
import { PassDataService } from '../pass-data.service';

@Component({
  selector: 'app-home-caroussel',
  templateUrl: './home-caroussel.component.html',
  styleUrls: ['./home-caroussel.component.css']
})
export class HomeCarousselComponent implements OnInit {

  constructor(private data: PassDataService) { }

  ngOnInit() {
  }
  device(device:any){
    console.log("inside",device);
    localStorage.setItem('device_trype',device);
      this.data.changeMessage("Hello from Sibling")
  }
}
