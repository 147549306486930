import { AuthModule } from './auth/auth.module';


import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { BillingModule } from './billing/billing.module';

import { CheckoutModule } from './checkout/checkout.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeCarousselComponent } from './home-caroussel/home-caroussel.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { HomesearchComponent } from './homesearch/homesearch.component';

import { HomeComponent } from './home/home.component';
import { AddmoreComponent } from './addmore/addmore.component';

import { IntroComponent } from './intro/intro.component';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { AboutUsComponent } from './about-us/about-us.component';
import { PrivacypolicyComponent } from './privacypolicy/privacypolicy.component';
import { TermsConditionComponent } from './terms-condition/terms-condition.component';
import { CancellationPolicyComponent } from './cancellation-policy/cancellation-policy.component';

import { ContactusComponent } from './contactus/contactus.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

import { FacebookModule } from 'ngx-facebook';
import { HowitworksComponent } from './howitworks/howitworks.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeCarousselComponent,
    HomesearchComponent,
    HomeComponent,
    AddmoreComponent,
    IntroComponent,
    AboutUsComponent,
    PrivacypolicyComponent,
    TermsConditionComponent,
    CancellationPolicyComponent,
    ContactusComponent,
    HowitworksComponent,
    
    // ConfirmationDialogComponent,
    
  ],
  imports: [
    BrowserModule,
   
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,

    AppRoutingModule,
    FacebookModule.forRoot(),
    BsDropdownModule.forRoot(),
    
    ServiceWorkerModule.register('./ngsw-worker.js', { enabled: environment.production })
  ],
  
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent],

})
export class AppModule { }
