import { apiurl } from './apiurlhelper';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EwplansService {

  constructor(private http: HttpClient) { }
  loadEWPlans(devicePrice) {
    let body = devicePrice ;
    return this.http.post(apiurl + "/getpackages", JSON.stringify(body))
  }

  loaddevicetype() {
    // let body = {group_id:group_id};
    let body = {};
    return this.http.post(apiurl + "/getdevicetype", JSON.stringify(body))
  }
  loadcountries() {
    let body = {  };
    return this.http.post(apiurl + "/getcountries", JSON.stringify(body))
  }
  loadgroups() {
    let body = {  };
    return this.http.post(apiurl + "/get_groups", JSON.stringify(body))
  }

}
