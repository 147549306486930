import { AddmoreComponent } from './addmore/addmore.component';
import { HomeComponent } from './home/home.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutUsComponent } from './about-us/about-us.component';
import { PrivacypolicyComponent } from './privacypolicy/privacypolicy.component';
import { TermsConditionComponent } from './terms-condition/terms-condition.component';
import { CancellationPolicyComponent } from './cancellation-policy/cancellation-policy.component';
import { ContactusComponent } from './contactus/contactus.component';
import { HowitworksComponent } from './howitworks/howitworks.component';


const routes: Routes = [
{
  path: '', component: HomeComponent
},
{ path: 'home', component: HomeComponent },
{ path: 'about', component: AboutUsComponent },
{ path: 'Privacy-policy', component: PrivacypolicyComponent },
{ path: 'terms-condition', component: TermsConditionComponent },
{ path: 'cancellation-policy', component: CancellationPolicyComponent },
{ path: 'contactus', component: ContactusComponent },
{ path: 'Howitworks', component: HowitworksComponent },

{
  path: 'addmore', component: AddmoreComponent},
  {
    path: 'billing',
    loadChildren: './billing/billing.module#BillingModule'
    // loadChildren: () => import('./billing/billing.module').then(m => m.BillingModule) 
  },
  {
    path: 'checkout',
    loadChildren: './checkout/checkout.module#CheckoutModule'
    // loadChildren: () => import('./checkout/checkout.module').then(m => m.CheckoutModule) 
  },
  {
    path: 'auth',
    loadChildren: './auth/auth.module#AuthModule'
    // loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) 
  },
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{anchorScrolling: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
