import { GeocoderService } from './../geocoder.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  
})
export class HomeComponent implements OnInit {

  mob_menu: boolean = false;
  hide_icon: any = false;
  constructor(private _geocode: GeocoderService,private router: Router) {
    let startDate:any= localStorage.getItem('verfied_time');
    if(startDate){
      var endDate   = new Date();
      var seconds = (endDate.getTime() - startDate) / 1000;
      console.log("ss",seconds);
      if(seconds>3600){
        localStorage.removeItem('verfied_time');
        localStorage.removeItem('user_info');
        localStorage.removeItem('user_verfied');
        localStorage.removeItem('cart');
        this.router.navigate(['/home'])
      }
    }
   }

  ngOnInit() {
    this.hide_icon = 'show';
    
    
  }
  meanu_toggle() {

    // this.hide_icon=false;
    this.mob_menu = !this.mob_menu;
  }
 
}
