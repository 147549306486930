import { Component, OnInit, Input, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { PassDataService } from '../pass-data.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  user: any;
  show_user: boolean;
  cart: any = 0;
  header_scrolled: boolean = false;
  activePage = 'Home';
  selectedCountry: string = '';
  @Input() cart_length: any;
  @Input() mob_menu: any;
  user_verify: any;
  message: string;

  // mob_menu:boolean=true;
  // hide_icon:any='show';
  constructor(private router: Router,private data: PassDataService) {
    this.data.currentMessage.subscribe(message => {
    console.log("TCL: HeaderComponent -> constructor -> message", message)
       this.cart_length = 0;
    })

    this.mob_menu = true;


  }
   diff_minutes(dt2, dt1) 
  {
 
   let diff =(dt2.getTime() - dt1.getTime()) / 1000;
   diff /= 60;
   return Math.abs(Math.round(diff));
   
  }
  @HostListener('window:scroll', ['$event']) onScrollEvent($event) {
    if (window.pageYOffset > 200) {
      this.header_scrolled = true;
    } else {
      this.header_scrolled = false;
    }
  }
  logout() {

    localStorage.setItem('user', JSON.stringify(''));
    localStorage.setItem('cart', JSON.stringify([]));
    this.router.navigate(['auth']);

  }

  ngOnInit() {
    this.cart = JSON.parse(localStorage.getItem('cart'));
    const user = JSON.parse(localStorage.getItem('user'));
    
    const verifiedUser = JSON.parse(localStorage.getItem('user_verfied'));
    const user_verificationTime = (localStorage.getItem('user_verificationTime'));
    
    if (verifiedUser) {
      this.user_verify = verifiedUser;
     
    }
    
    console.log("TCL: HeaderComponent -> constructor -> userver", this.user_verify)
    if (user) {
      this.user = user;
      this.show_user = true;
    } else {
      this.show_user = false;
    }
    this.selectedCountry = localStorage.getItem("country");
    if(this.cart){
      this.cart_length = this.cart.length;
    }
   
    
  }

}
