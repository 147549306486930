import { Component, OnInit, Input } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-privacypolicy',
  templateUrl: './privacypolicy.component.html',
  styleUrls: ['./privacypolicy.component.css']
})
export class PrivacypolicyComponent implements OnInit {
  mob_menu: boolean = false;
  hide_icon: any = false;
  constructor(private router: Router) { 
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
    let startDate:any= localStorage.getItem('verfied_time');
    if(startDate){
      var endDate   = new Date();
      var seconds = (endDate.getTime() - startDate) / 1000;
      console.log("ss",seconds);
      if(seconds>3600){
        localStorage.removeItem('verfied_time');
        localStorage.removeItem('user_info');
        localStorage.removeItem('user_verfied');
        localStorage.removeItem('cart');
        this.router.navigate(['/home'])
      }
    }
  }

  ngOnInit() {
    this.hide_icon = 'show';
    
    
  }
  meanu_toggle() {

    // this.hide_icon=false;
    this.mob_menu = !this.mob_menu;
  }
}
