import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { apiurl } from './apiurlhelper';

@Injectable({
  providedIn: 'root'
})
export class ContactusService {

  constructor(private http: HttpClient) { }
  contact_us(contactus){
    
    return this.http.post(apiurl+"/contactus",JSON.stringify(contactus))
  }
}
