import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class PassDataService {
  private messageSource = new BehaviorSubject(' message');
  currentMessage = this.messageSource.asObservable();
  constructor() { }
  changeMessage(message: string) {
    console.log("TCL: PassDataService -> changeMessage -> message", message)
    this.messageSource.next(message);
  }
}
